<template>
    <el-button 
      v-for="(item, index) in menuList" 
      :type="item.dom_class" 
      :key="index"
      :icon="item.icon_name"
      @click="HeadClick(item.dom_id)">
      {{ item.name }}
    </el-button>
</template>

<script>

export default {
  name: 'headMenu',
  props: {
    modulecode: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modulecode','buttonClick','loaded'],
  data() {
    return {
      menuList: [],
    };
  },
  mounted() {
    this.getHeadMenu()
    
  },
  methods: {
    //获取头部按钮
    getHeadMenu() {
      this.$axios.get("/web/ModuleManager/LoadAuthorizedMenus", {
          params: {
            modulecode: this.modulecode,
          },
        }).then((data) => {
          if (data.data) {
            this.menuList = data.data;
            this.$nextTick(() => {
              this.$emit('loaded')
            });
          }
        }
      )
    },
    HeadClick(val){
      this.$emit('buttonClick', val)
    },
  },
};
</script>