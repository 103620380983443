<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      windowResize: {h: window.innerHeight, w: window.innerWidth},
    }
  },
  methods: {
    // // 初始化
    // initConfig () {
    //   let _this = this
    //   _this.$router.push({'path': '/'})
    // },
    saveState() {
      sessionStorage.setItem('state', JSON.stringify(this.$store.state))
    }
  },
  mounted() {
    // unload页面时把store存到浏览器
    window.addEventListener('unload', this.saveState)
    window.onresize = () => {
      this.windowResize.h = window.innerHeight
      this.windowResize.w = window.innerWidth
    }
  },
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
} */
/* #app, body, html { */
body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
