export default {
  // 设备数据二级菜单名称
  handleClick(state, name) {
    state.sbdjSecondTitle = name
    try {
      localStorage.sbdjSecondTitle = name
    } catch (e) {}
  },
  // 设备数据三级菜单是否显示
  thirdTitleClick(state, visible) {
    state.isThirdVisible = visible
    try {
      localStorage.isThirdVisible = visible
    } catch (e) {}
  },
  // 获取当前土壤墒情仪
  getCurrentTrsqy(state, item) {
    state.currentTrsqy = item
    try {
      localStorage.currentTrsqy = item
    } catch (e) {}
  },
  // 获取当前气象观测站
  getCurrentQxgcz(state, item) {
    state.currentQxgcz = item
    try {
      localStorage.currentQxgcz = item
    } catch (e) {}
  },
  // 获取当前虫情测报仪
  getCurrentCqcby(state, item) {
    state.currentCqcby = item
    try {
      localStorage.currentCqcby = item
    } catch (e) {}
  },
  // 用户信息
  userInfoHandleClick(state, userInfo) {
    state.userInfo = userInfo
    try {
      localStorage.userInfo = JSON.stringify(userInfo);
    } catch (e) {}
  },
}
