import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/Login/Login'),
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import('../pages/Home/Home'),
    children: [
      {
        path: '/OneMaps',
        name: 'OneMaps',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/OneMaps/PlantingManagementOneMapIndex',
            name: 'OneMapPlantingManagement',
            component: () => import('../pages/OneMaps/PlantingManagementOneMapIndex')
          }, {
            path: '/OneMaps/BasicResourcesOneMapIndex',
            name: 'OneMapBasicResources',
            component: () => import('../pages/OneMaps/BasicResourcesOneMapIndex')
          },
        ]
      },
      // {
      //   path: '/DeviceManage',
      //   name: 'DeviceManage',
      //   component: () => import('../pages/Home/HomeLeft'),
      //   children: [
      //     {
      //       path: '/DeviceManage/trsqy',
      //       name: 'trsqy',
      //       component: () => import('../pages/DeviceManage/trsqy')
      //     },
      //     {
      //       path: '/DeviceManage/cqcby',
      //       name: 'cqcby',
      //       component: () => import('../pages/DeviceManage/cqcby')
      //     },
      //     {
      //       path: '/DeviceManage/qxgcz',
      //       name: 'qxgcz',
      //       component: () => import('../pages/DeviceManage/qxgcz')
      //     },
      //     {
      //       path: '/DeviceManage/gqsxt',
      //       name: 'gqsxt',
      //       component: () => import('../pages/DeviceManage/gqsxt')
      //     },
      //     {
      //       path: '/DeviceManage/jwjgy',
      //       name: 'jwjgy',
      //       component: () => import('../pages/DeviceManage/jwjgy')
      //     }
      //   ]
      // },
      {
        path: '/Manager',
        name: 'Manager',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/Manager/DictConfig',
            name: 'DictConfig',
            component: () => import('../pages/Manager/DictConfig')
          },
          {
            path: '/Manager/OrgConfig',
            name: 'OrgConfig',
            component: () => import('../pages/Manager/OrgConfig')
          },
          {
            path: '/Manager/RegionManager',
            name: 'RegionManager',
            component: () => import('../pages/Manager/RegionManager')
          },
          {
            path: '/Manager/sblbManager',
            name: 'sblbManager',
            component: () => import('../pages/Manager/sblbManager')
          },
          {
            path: '/Manager/sbxxManager',
            name: 'sbxxManager',
            component: () => import('../pages/Manager/sbxxManager')
          },
          {
            path: '/Manager/dwry',
            name: 'dwry',
            component: () => import('../pages/Manager/dwry')
          },
          {
            path: '/Manager/UserManage/UserConfig',
            name: 'UserConfig',
            component: () => import('../pages/Manager/UserManage/UserConfig')
          },
          {
            path: '/Manager/UserManage/SafeConfig',
            name: 'SafeConfig',
            component: () => import('../pages/Manager/UserManage/SafeConfig')
          },
          {
            path: '/Manager/RoleConfig',
            name: 'RoleConfig',
            component: () => import('../pages/Manager/RoleConfig')
          },
          {
            path: '/Manager/ModulesConfig',
            name: 'ModulesConfig',
            component: () => import('../pages/Manager/ModulesConfig')
          },
          {
            path: '/Manager/DailyRecordManage',
            name: 'DailyRecordManage',
            component: () => import('../pages/Manager/DailyRecordManage')
          },
          {
            path: '/Manager/jczyManager',
            name: 'jczyManager',
            component: () => import('../pages/Manager/jczyManager')
          },
          {
            path: '/Manager/ygsjManager',
            name: 'ygsjManager',
            component: () => import('../pages/Manager/ygsjManager')
          }
        ]
      },
      {
        path: '/Facility',
        name: 'Facility',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/Facility/dkgl',
            name: 'dkgl',
            component: () => import('../pages/Facility/dkgl')
          },
          {
            path: '/Facility/nsxx',
            name: 'nsxx',
            component: () => import('../pages/Facility/nsxx')
          },
          {
            path: '/Facility/xtgl',
            name: 'xtgl',
            component: () => import('../pages/Facility/xtgl')
          },
          {
            path: '/Facility/zwxx',
            name: 'zwxx',
            component: () => import('../pages/Facility/zwxx')
          },
        ]
      },
      {
        path: '/ReportCenter',
        name: 'ReportCenter',
        component: () => import('../pages/Home/HomeLeft'),
        children: [
          {
            path: '/ReportCenter/ntsjfx',
            name: 'ntsjfx',
            component: () => import('../pages/ReportCenter/ntsjfx')
          },
          {
            path: '/ReportCenter/zwsjfx',
            name: 'zwsjfx',
            component: () => import('../pages/ReportCenter/zwsjfx')
          }
        ]
      }
    ]
  },
  {
    path: '/HomeSbsj',
    name: 'HomeSbsj',
    component: () => import('../pages/Home/HomeSbsj'),
    children: [
      {
        path: '/DeviceManage',
        name: 'DeviceManage',
        component: () => import('../pages/Home/HomeSbsjLeft'),
        children: [
          {
            path: '/DeviceManage/trsqy',
            name: 'trsqy',
            component: () => import('../pages/DeviceManage/trsqy')
          },
          {
            path: '/DeviceManage/trsqyXQ',
            name: 'trsqyXQ',
            component: () => import('../pages/DeviceManage/trsqyXQ')
          },
          {
            path: '/DeviceManage/cqcby',
            name: 'cqcby',
            component: () => import('../pages/DeviceManage/cqcby')
          },
          {
            path: '/DeviceManage/cqcbyXQ',
            name: 'cqcbyXQ',
            component: () => import('../pages/DeviceManage/cqcbyXQ')
          },
          {
            path: '/DeviceManage/qxgcz',
            name: 'qxgcz',
            component: () => import('../pages/DeviceManage/qxgcz')
          },
          {
            path: '/DeviceManage/qxgczXQ',
            name: 'qxgczXQ',
            component: () => import('../pages/DeviceManage/qxgczXQ')
          },
          {
            path: '/DeviceManage/gqsxt',
            name: 'gqsxt',
            component: () => import('../pages/DeviceManage/gqsxt')
          },
          {
            path: '/DeviceManage/jwjgy',
            name: 'jwjgy',
            component: () => import('../pages/DeviceManage/jwjgy')
          }
        ]
      },
    ]
  },
  {
    path: '/DeviceManage/viewLocationMap',
    name: 'viewLocationMap',
    component: () => import('../pages/DeviceManage/viewLocationMap')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// }
export default router
