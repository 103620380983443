let sbdjSecondTitle = '设备类别'  // 设备数据二级菜单名称
let isThirdVisible = false  // 设备数据三级菜单是否显示
let currentTrsqy = {}  // 当前土壤墒情仪
let currentQxgcz = {}  // 当前气象观测站
let currentCqcby = {}  // 当前虫情测报仪

try {
  if (localStorage.sbdjSecondTitle) {
    sbdjSecondTitle = localStorage.sbdjSecondTitle
  }
  if (localStorage.isThirdVisible) {
    isThirdVisible = localStorage.isThirdVisible
  }
  if (localStorage.currentTrsqy) {
    currentTrsqy = localStorage.currentTrsqy
  }
  if (localStorage.currentQxgcz) {
    currentQxgcz = localStorage.currentQxgcz
  }
  if (localStorage.currentCqcby) {
    currentCqcby = localStorage.currentCqcby
  }
} catch (e) {}

export default {
  sbdjSecondTitle: sbdjSecondTitle,
  isThirdVisible: isThirdVisible,
  currentTrsqy: currentTrsqy,
  currentQxgcz: currentQxgcz,
  currentCqcby: currentCqcby,
}

